import "../styles/Pricing.css";
import {Badge, BadgeIcon} from "./Badge";
import * as React from "react";
import {Button, ButtonType} from "./Button";
import {Config} from "../Config";
import GlowButton, {GlowButtonType} from "./GlowButton";

interface PricingProps {
    bookACall     : () => void;
}
export const Pricing : React.FC<PricingProps> = (props) => {
    return <div className="pricing" id={Config.PageSections.Pricing}>
        <Badge>Simple pricing</Badge>
        <h2>
            Transparent packages, <span>no hidden fees</span>
        </h2>
        <p className="margin">
            Our pricing is simple. No long-term commitments, just a straightforward monthly fee. Plus, brace yourself for designs delivered at lightning speed.
        </p>
        <div className="cards">
            <div className="card standard">
                <h2>Standard</h2>
                <Badge>Popular</Badge>
                <p>Ideal if you need continuous design support.</p>
                <div className="price">1895 <span>€/month</span></div>
                <GlowButton type={GlowButtonType.Button} buttonType={ButtonType.CallToAction} onClick={props.bookACall}>Book a 20-min intro call</GlowButton>
                <div className="list">
                    <div className="list-item strong time">Experienced designer at your service</div>
                    <div className="list-item strong design">Full-service design</div>
                    <div className="list-item strong repeat">One request at a time</div>
                    <div className="list-item pause">Pause or cancel anytime</div>
                    <div className="list-item delivery">Average 48 hour delivery</div>
                    <div className="list-item check">Available 5 days/week</div>
                </div>
            </div>
            <div className="card business">
                <h2>Business Pro</h2>
                <Badge className="">Superfast</Badge>
                <p>Ideal if you need full-scale design and development service.</p>
                <div className="price">4995 <span>€/month</span></div>
                <GlowButton type={GlowButtonType.Button} buttonType={ButtonType.CallToAction} onClick={props.bookACall}>Book a 20-min intro call</GlowButton>
                <div className="list">
                    <div className="list-item strong time">Designer + Developer at your service</div>
                    <div className="list-item strong design">Product + website design</div>
                    <div className="list-item strong repeat">Double the requests</div>
                    <div className="list-item pause">Pause or cancel anytime</div>
                    <div className="list-item delivery">Average 48 hour delivery</div>
                    <div className="list-item check">Available 5 days/week</div>
                </div>
            </div>
            <div className="card boost" style={{backgroundImage : "url(" + process.env.PUBLIC_URL + "/assets/Dribbble_black_friday_2.png)"}}>
                <div className="left-side">
                    <h2>Brand Boost</h2>
                    <Badge className="yellow">Best value</Badge>
                    <p>Great if you need fresh or updated brand design on a full scale.</p>
                    <div className="price">3500 <span>€/project</span></div>
                    <GlowButton type={GlowButtonType.Button} buttonType={ButtonType.CallToAction} onClick={props.bookACall}>Book a call</GlowButton>
                    <div className="list">
                        <div className="list-item strong palette">Logo + brand elements</div>
                        <div className="list-item strong color">Color palette + typography</div>
                        <div className="list-item strong design">Illustrations</div>
                        <div className="list-item camera">Custom photo + video work</div>
                        <div className="list-item social">Social media design</div>
                        <div className="list-item print">Print materials (flyers, rollups etc.)</div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
