import '../styles/MainMenu.css';
import * as React from "react";
import {Button, ButtonIcon, ButtonType} from "./Button";
import {Config} from "../Config";
import {useEffect, useRef, useState} from "react";
import GlowButton, {GlowButtonType} from "./GlowButton";

interface MainMenuProps {
    bookACall     : () => void;
}
export const MainMenu : React.FC<MainMenuProps> = (props) => {
    const animate1  = useRef(null);
    const animate2  = useRef(null);
    const animate3  = useRef(null);
    const menuRef   = useRef(null);

    const [isExpanded, setIsExpanded] = useState(false);


    // Keyframes
    const values1 = [
        "M 84,24 C 84,24 61.333333,24.001 50,24.001 38.666667,24.001 16,24 16,24",
        "M 82,24 C 82,24 66.957389,30.5 50,30.5 33.042611,30.5 18,24 18,24",
        "M 80,24 C 80,24 61.663104,37 50,37 38.336896,37 20,24 20,24",
        "M 78,24 C 78,24 55.685686,43.5 50,43.5 44.314314,43.5 22,24 22,24",
        "M 76,24 C 76,24 50.055365,50 50,50 49.94463,50 24,24 24,24"
    ];
    const values2 = [
        "M 84,50 H 50 16",
        "M 75.5,50 H 50 24.5",
        "M 67,50 H 50 33",
        "M 58.5,50 H 50 41.5",
        "M 50.001,50 H 50 49.99"
    ];
    const values3 = [
        "M 84,76 C 84,76 61.333333,76.001 50,76.001 38.666667,76.001 16,76 16,76",
        "M 82,76 C 82,76 66.957389,69.5 50,69.5 33.042611,69.5 18,76 18,76",
        "M 80,76 C 80,76 61.663104,63 50,63 38.336896,63 20,76 20,76",
        "M 78,76 C 78,76 55.685686,56.5 50,56.5 44.314314,56.5 22,76 22,76",
        "M 76,76 C 76,76 50.055365,50 50,50 49.944635,50 24,76 24,76"
    ];

    useEffect(() => {
        initPath(".path1", values1[0]);
        initPath(".path2", values2[0]);
        initPath(".path3", values3[0]);

    }, [])
    useEffect(() => {
        initPath(".path1", values1[isExpanded ? 4 : 0]);
        initPath(".path2", values2[isExpanded ? 4 : 0]);
        initPath(".path3", values3[isExpanded ? 4 : 0]);

    }, [isExpanded])

    function toggleAnimation(values, animate) {
        if(animate.current) {
            animate.current.setAttribute(
                "values",
                !isExpanded ? values.join("; ") : values.slice().reverse().join("; ")
            );
            animate.current.beginElement();
        }
    }

    function initPath(clazz, descriptor) {
        // @ts-ignore
        const path = window.document.querySelector(clazz);
        path.setAttribute("d", descriptor);
    }

    function toggleMenu() {
        setIsExpanded(!isExpanded);

        toggleAnimation(values1, animate1);
        toggleAnimation(values2, animate2);
        toggleAnimation(values3, animate3);

        menuRef.current && menuRef.current.setAttribute("aria-expanded", !isExpanded);
        menuRef.current && menuRef.current.setAttribute("aria-label", !isExpanded ? "Close Menu" : "Open Menu");
    }

    return  <div className={"main-menu" + (isExpanded ? " opened" : "")} ref={menuRef}>
        <div className={"left-side-wrapper"}>
            <a className="logo" href={"/"}>
                <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt={"najsu agency logo"}/>
            </a>
            <nav className="menu-navigation">
                <a className="" onClick={e => setIsExpanded(false)} href={"#" + Config.PageSections.HowItWorks}>How it works</a>
                <a className="" onClick={e => setIsExpanded(false)} href={"#" + Config.PageSections.Services}>Services</a>
                <a className="" onClick={e => setIsExpanded(false)} href={"#" + Config.PageSections.Pricing}>Pricing</a>
                <a className="" onClick={e => setIsExpanded(false)} href={"#" + Config.PageSections.FAQ}>FAQ</a>
            </nav>
        </div>
        <div className={"cta-buttons"}>
            <Button classes={["light"]} type={ButtonType.Link} icon={ButtonIcon.Figma} onClick={Config.ShowcaseLink}>See our work</Button>
            <GlowButton type={GlowButtonType.Button} buttonType={ButtonType.CallToAction} onClick={props.bookACall}>Let's talk</GlowButton>
        </div>
        <svg width="32" height="32" viewBox="0 0 100 100" className="icon" onClick={toggleMenu}>
            <path className="path1">
                <animate className="animate1" attributeName="d" dur="0.35s" repeatCount="1" fill="freeze" ref={animate1}/>
            </path>
            <path className="path2">
                <animate className="animate2" attributeName="d" dur="0.35s" repeatCount="1" fill="freeze" ref={animate2}/>
            </path>
            <path className="path3">
                <animate className="animate3" attributeName="d" dur="0.35s" repeatCount="1" fill="freeze" ref={animate3}/>
            </path>
        </svg>
    </div>;
}
