import "../styles/BannerCTA.css";
import * as React from "react";
import {Button, ButtonType} from "./Button";
import {DesignerPointer, DeveloperPointer} from "./Pointers";
import GlowButton, {GlowButtonType} from "./GlowButton";
import {Config} from "../Config";

interface BannerCTAProps {
    bookACall     : () => void;
}

export const BannerCTA : React.FC<BannerCTAProps> = (props) => {
    return <div className="banner-cta">
        <DesignerPointer />
        <DeveloperPointer/>
        <div className="icons">
            <img src={process.env.PUBLIC_URL + "/assets/us_two.png"} alt={"Najsu Agency Team"}/>
            <span>+ You</span>
        </div>
        <h2>
            See if <span>najsu is right for you</span> and start transforming your vision into reality
        </h2>
        <p className="margin">
            Book a free call with us to see if we're a good match
        </p>
        <GlowButton type={GlowButtonType.Button} className={"align-center"} buttonType={ButtonType.Primary} onClick={props.bookACall}>Book a 20-min intro call</GlowButton>
    </div>;
}
