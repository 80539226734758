import '../styles/HeroSection.css';
import * as React from "react";
import {useState} from "react";
import {ButtonType} from "./Button";
import {Carousel} from "./Carousel";
import {ClientPointer, DesignerPointer, DeveloperPointer} from "./Pointers";
import {Config} from "../Config";
import InfiniteLogoMarquee from "./InfiniteLogoMarquee";
import GlowButton, {GlowButtonType} from "./GlowButton";
import {Simulate} from "react-dom/test-utils";

interface HeroSectionProps {
    bookACall     : () => void;
}
export const HeroSection : React.FC<HeroSectionProps> = (props) => {

    function getItemsForCarousel() {
        return [
            <div>
                <img src={process.env.PUBLIC_URL + '/assets/hero_section/black_friday.png'} alt={"Landing page design"} />
            </div>,
            <div>
                <img src={process.env.PUBLIC_URL + '/assets/hero_section/plant_shop.png'} alt={"UI/UX Design Interface"} />
            </div>,
            <div>
                <img src={process.env.PUBLIC_URL + '/assets/hero_section/glassmorphism_exploration.png'} alt={"Web design layout"} />
            </div>,
            <div>
                <img src={process.env.PUBLIC_URL + '/assets/hero_section/Customerscore_homepage.png'} alt={"Web design and mockups"} />
            </div>,
            <div>
                <img src={process.env.PUBLIC_URL + '/assets/hero_section/teamio.png'} alt={"UI/UX Design Interface"} />
            </div>,
            <div>
                <img src={process.env.PUBLIC_URL + '/assets/hero_section/newsletter.png'} alt={"Visual Design"} />
            </div>,
            <div>
                <img src={process.env.PUBLIC_URL + '/assets/hero_section/Help_center.png'} alt={"Visual Design"} />
            </div>,
            <div>
                <img src={process.env.PUBLIC_URL + '/assets/hero_section/AI_shopping_assistant.png'} alt={"UI/UX desktop app design"} />
            </div>
        ];
    }

    return <div className="hero-section">
        <DesignerPointer />
        <DeveloperPointer/>
        <h1>
            Reliable <span>design partner</span><br/>
            for startups and creative agencies
        </h1>
        <p className="margin">
            The only design subscription you’ll ever need.<br/>
            Scale your business with our unlimited design & code services.
        </p>
        <GlowButton type={GlowButtonType.Link} className={"align-center"} buttonType={ButtonType.Primary} onClick={"#" + Config.PageSections.Pricing}>See our plans</GlowButton>

        <div className={"availability-wrapper"}>
            <div className={"available"}>
                <div className={"circle-1"}></div>
                <div className={"circle-2"}></div>
                <div className={"circle-3"}></div>
            </div>
            Available for new projects
        </div>

        <Carousel items={getItemsForCarousel()} />

        <p className={"over-logo margin"}>Many have already put their trust in us...</p>

        <InfiniteLogoMarquee />
    </div>;
}
