import "../styles/HowItWorks.css";
import {Badge, BadgeIcon} from "./Badge";
import * as React from "react";
import {BenefitIcons, BenefitItem} from "./BenefitItem";
import {Config} from "../Config";

export function HowItWorks(props) {
    return <div className="how-it-works" id={Config.PageSections.HowItWorks}>
        <Badge>How it works</Badge>
        <h2>
            Transform your <span>design experience</span><br/>
            with our <span>subscription-based</span> solution
        </h2>
        <p className="margin">
            Design made simple—just the way you want it. No unreliable freelancers,
            no costly agencies. Just a fixed monthly fee for design & code.
        </p>
        <div className="benefit-items">
            <BenefitItem icon={BenefitIcons.Money} heading={"Easy subscription"} body={"Subscribe to a plan that best suits you needs and start requesting your ideal designs."}/>
            <BenefitItem icon={BenefitIcons.Megaphone} heading={"Unlimited requests"} body={"Get your design delivered within two business days, on average."}/>
            <BenefitItem icon={BenefitIcons.Like} heading={"Delightful results"} body={"We're here to refine your designs until they match your vision perfectly."}/>
        </div>
    </div>;
}
