import "../styles/CardDeck.css";
import React, {ReactElement} from "react";

export interface CardProps {
    id: number;
    content: ReactElement | string;
    isActive: boolean;
}

const Card : React.FC<CardProps> = ({ id, content, isActive }) => {
    return (
        <div className={"card" + (isActive ? ' active' : '')}>
            {content}
        </div>
    );
};

export function CardDeck (props : { active : number, cards : { id: number, content: ReactElement | string}[] }) {
    return (
        <div className="card-deck">
            {props.cards.map((card) => (
                <Card
                    key={card.id}
                    id={card.id}
                    content={card.content}
                    isActive={props.active === card.id}
                />
            ))}
        </div>
    );
};