import "../styles/Badge.css";

export function Badge(props) {
    return <div className={"badge" + (props.className ? (" " + props.className) : "")}>
        {props.icon && <div className={"icon " + props.icon}></div>}
        <span>{props.children}</span>
    </div>
}

export const BadgeIcon = {
    Coffee: "coffee",
    Service: "service",
    Price: "price",
    Hearth: "heart"

}
