import "../styles/Testimonials.css";
import {Badge, BadgeIcon} from "./Badge";
import * as React from "react";
import {ReactHTMLElement, useEffect} from "react";

let interval;

export function Testimonials(props) {
    const [active, setActive] = React.useState(0);

    const testimonials = [
        {
            content : "Thanks to <i>najsu</i>, our fully automated website manages invoicing, payments, course alerts, and feedback. This allows us to focus more on teaching and less on admin tasks. Our website also serves as our primary marketing tool, helping us practically double our revenue from public courses.",
            image   : "/assets/j_richter.jpg",
            alt     : "",
            name    : "MUDr. Jan Richter",
            role    : "Lecturer and founder of ucimeprvnipomoc.cz"
        },
        {
            content : "I've known Maria for years and have always been impressed by her work. As a talented designer focused on SaaS websites, she delivered not just great mockups but the entire website. She quickly implemented feedback, making the process smooth and efficient. I highly recommend her design services.",
            image   : "/assets/t_horacek.png",
            alt     : "",
            name    : "Tomáš Horáček",
            role    : "CEO of customerscore.io"
        }

    ];

    useEffect(() => {
        resetInterval();
        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        resetInterval();
    }, [active])


    function resetInterval() {
        clearInterval(interval);
        interval = setInterval(() => {
            setActive((active + 1) % testimonials.length);
        }, 10 * 1000)
    }

    function renderTestimonial(content : string | TrustedHTML, image : string, alt : string, name : string, role : string, index : number) {
        return <div className={"testimonial"} key={"testimonial_" + index}>
            <div className="content" dangerouslySetInnerHTML={{__html : content}}></div>
            <div className="person">
                {renderBtn("left", -1, index === 0)}
                <img src={process.env.PUBLIC_URL + image} alt={alt}/>
                <div className="person-info">
                    <div className="person-name">{name}</div>
                    <div className="person-role">{role}</div>
                </div>
                {renderBtn("right", 1, index >= testimonials.length - 1)}
            </div>
        </div>
    }

    function renderBtn(className : string, counterAdd : number, disabled : boolean) {
        return <div className={"button " + className + (disabled ? " disabled" : "")} onClick={e => {
            setActive((active + counterAdd) % testimonials.length);
        }}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <circle cx="13" cy="13" r="12.5" stroke="#180030"/>
                    <g>
                        <path d="M14.7916 9L11.4982 12.2933C11.3125 12.4817 11.2085 12.7355 11.2085 13C11.2085 13.2645 11.3125 13.5183 11.4982 13.7067L14.7916 17" stroke="#180030" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </g>
            </svg>
        </div>
    }


    return <div className="testimonials">
        <Badge icon={BadgeIcon.Hearth}>THEY SAY ABOUT US</Badge>
        <div className={"testimonials-hidden-wrapper"}>
            <div className={"testimonials-wrapper"} style={{transform : "translateX(" + -1 * active * 100 + "%)"}}>
                {
                    testimonials.map((testimonial, i) => renderTestimonial(testimonial.content, testimonial.image, testimonial.alt, testimonial.name, testimonial.role, i))
                }
            </div>
        </div>
    </div>;
}
