import "../styles/WhatWeDo.css";
import {Badge, BadgeIcon} from "./Badge";
import * as React from "react";
import {CardDeck} from "./CardDeck";
import {Config} from "../Config";

export function WhatWeDo() {
    const [active, setActive] = React.useState(1);
    const [cards, setCards] = React.useState([
        {
            id: 1,
            content: <div className={"card-content-wrapper"}>
                <div className="left-side">
                    <h3>Websites & landing pages that convert</h3>
                    <p>
                        Stuck with a visually outdated website that struggles to convey the true value of your
                        products
                        or services?
                    </p>
                    <p>
                        Let us design and build a website that:
                    </p>
                    <ul>
                        <li>Is on-brand, reflecting your unique identity</li>
                        <li>Addresses your audience's wants and needs</li>
                        <li>Is technically sound and provides smooth UX</li>
                    </ul>
                </div>
                <div className="right-side">
                    <img src={process.env.PUBLIC_URL + "/assets/Dribbble_black_friday_2.png"}/>
                </div>
            </div>
        },
        {
            id: 2,
            content: <div className={"card-content-wrapper"}>
            <div className="left-side">
                    <h3>Web & mobile apps
                        with stunning visuals</h3>
                    <p>
                        Are your web and mobile apps lacking the visual impact necessary to communicate your brand essence effectively?
                    </p>
                    <p>
                        We can assist with:
                    </p>
                    <ul>
                        <li>Captivating UI that embodies your identity</li>
                        <li>Establishing unified design systems</li>
                        <li>Improving engagement with intuitive designs</li>
                    </ul>
                </div>
                <div className="right-side">
                    <img src={process.env.PUBLIC_URL + "/assets/Teamio_dribbble.png"} alt={"Full-Stack Development"}/>
                </div>
            </div>
        },
        {
            id: 0,
            content: <div className={"card-content-wrapper"}>
                <div className="left-side">
                    <h3>Visual branding that helps you stand out</h3>
                    <p>
                        Feeling that your inconsistent branding visuals fail to effectively communicate the essence of
                        your brand?
                    </p>
                    <p>
                        We can help you though the following:
                    </p>
                    <ul>
                        <li>Fresh visual identity your audience will love</li>
                        <li>Original graphics and promotional content</li>
                        <li>Photos and videos that will help you stand out</li>
                    </ul>
                </div>
                <div className="right-side">
                    <img src={process.env.PUBLIC_URL + "/assets/Dribbble_black_friday_2.png"}/>
                </div>
            </div>
        }
    ]);

    function handleSetActive(clickedCardId : number) {
        const clickedCardIndex = cards.findIndex(card => card.id === clickedCardId);
        const newCards = [...cards.slice(clickedCardIndex), ...cards.slice(0, clickedCardIndex)];
        setCards(newCards);
        setActive(clickedCardId);
    }

    return <div className="what-we-do" id={Config.PageSections.Services}>
        <Badge>What we do</Badge>
        <h2>
            Our clients <span>trust our expertise</span> in...
        </h2>
        <p className="margin">
            From web design to branding, through social media, and print design,
            we deliver captivating solutions tailored to your brand's vision.
        </p>
        <div className="labels">
            <button className={"label" + (active === 0 ? " active" : "")} onClick={e => handleSetActive(0)}><span>Branding</span></button>
            <button className={"label" + (active === 1 ? " active" : "")} onClick={e => handleSetActive(1)}><span>Websites</span></button>
            <button className={"label" + (active === 2 ? " active" : "")} onClick={e => handleSetActive(2)}><span>Products</span></button>
        </div>
        <CardDeck cards={cards} active={active} />
    </div>;
}
