import React  from 'react';
import '../styles/InfiniteLogoMarquee.css';

export default function InfiniteLogoMarquee() {
    let logos = [
        process.env.PUBLIC_URL + '/assets/logos/lakioo.png',
        process.env.PUBLIC_URL + '/assets/logos/traininghub.png',
        process.env.PUBLIC_URL + '/assets/logos/smartsupp.png',
        process.env.PUBLIC_URL + '/assets/logos/ucime-prvni-pomoc.png',
        process.env.PUBLIC_URL + '/assets/logos/customerscore.png',
        process.env.PUBLIC_URL + '/assets/logos/Your future logo.png',
        process.env.PUBLIC_URL + '/assets/logos/zpsx.png'
    ];
    let logosDouble = logos.concat(logos).concat(logos);

    return (
        <div className="marquee">
            <div className="marquee-content">
                {logosDouble.map((logo, index) => (
                    <img key={index} src={logo} alt={`Logo ${index}`} className="marquee-logo" />
                ))}
            </div>

            <div className="marquee-content-static">
                {logos.map((logo, index) => (
                    <img key={index} src={logo} alt={`Logo ${index}`} className="marquee-logo" />
                ))}
            </div>
        </div>
    );
};

