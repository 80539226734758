import '../styles/Carousel.css';
import {useEffect, useRef, useState} from "react";

let startX = 0;
let startMoveX = 0;
const minScroll = -50;
export function Carousel( {items, ...props} ) {
    const [scrollLeft, setScrollLeft] = useState(minScroll);
    const [moveLeft, setMoveLeft] = useState(0);

    const carouselRef = useRef(null);

    useEffect(() => {
        const handleScroll = (e) => {console.log(e);
            setScrollLeft((window.scrollY / 2) - 50);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function onMouseDown(e) {
        e.preventDefault();

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);

        startX      = e.clientX;
        startMoveX  = moveLeft;
    }

    function onMouseMove(e) {

        const diff = e.clientX - startX;
        let newMoveLeft = startMoveX - diff;
        const bbox = carouselRef.current.getBoundingClientRect();
        const maxWidth = items.length * (483 + 50) - bbox.width;

        newMoveLeft = Math.max(0, Math.min(newMoveLeft, maxWidth));

        setMoveLeft(newMoveLeft);
    }

    function onMouseUp() {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);

    }


    let currentScroll = (scrollLeft + moveLeft);

    if(currentScroll < minScroll) {
        currentScroll = minScroll;
    }

    return (
        <div className="carousel-wrapper">
            <div className="carousel" style={{transform: "translateX(" + (-1 * currentScroll) + "px)"}} onMouseDown={onMouseDown} ref={carouselRef}>
                {items.map((item, index) => (
                    <div key={index} className="carousel-item">
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
}
