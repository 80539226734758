import * as React from "react";
import "../styles/BenefitItem.css";

export function BenefitItem(props) {
    return <div className="benefit-item">
        <div className="icon">
            {props.icon}
        </div>
        <div className="heading">
            {props.heading}
        </div>
        <p>
            {props.body}
        </p>
    </div>
}

export const BenefitIcons = {
    Money       : <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2812 32.7853L9.21876 28.7164L5.15625 32.7853" stroke="url(#paint0_linear_83_1219)"
              strokeWidth="3.4375" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M41.7188 22.2919L45.7813 26.3608L49.8437 22.2919" stroke="url(#paint1_linear_83_1219)"
              strokeWidth="3.4375" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M45.781 26.3608C45.781 21.5047 43.8551 16.8476 40.4268 13.4139C36.9982 9.98014 32.3484 8.05111 27.4999 8.05111C25.1677 8.04765 22.8569 8.4967 20.6952 9.37347C18.2825 10.3403 16.1034 11.8111 14.303 13.6879C12.5027 15.5647 11.1225 17.8044 10.2546 20.2576"
            stroke="url(#paint2_linear_83_1219)" strokeWidth="3.4375" strokeLinecap="round"
            strokeLinejoin="round"/>
        <path
            d="M9.21869 28.7164C9.22254 32.9672 10.703 37.0842 13.4062 40.3613C16.1094 43.6384 19.8669 45.8714 24.0341 46.6774C28.2015 47.4831 32.5187 46.8116 36.2457 44.778C39.9728 42.7446 42.8771 39.4756 44.4608 35.5316"
            stroke="url(#paint3_linear_83_1219)" strokeWidth="3.4375" strokeLinecap="round"
            strokeLinejoin="round"/>
        <path
            d="M21.9552 32.0334C22.0731 33.3958 22.7201 34.6573 23.757 35.547C24.7938 36.4366 26.1379 36.8832 27.5 36.7909C31.9729 36.7909 33.0447 34.2178 33.0447 32.0334C33.0447 29.8492 31.1965 27.5353 27.5 27.5353C23.8036 27.5353 21.9552 26.0727 21.9552 23.0924C21.9897 22.4068 22.1623 21.7353 22.4626 21.1181C22.7629 20.501 23.1846 19.9511 23.7027 19.5015C24.2207 19.0519 24.824 18.7119 25.4767 18.5019C26.1292 18.2919 26.8176 18.2162 27.5 18.2795C28.8631 18.2207 30.1971 18.6866 31.2281 19.5816C32.2591 20.4765 32.9093 21.7327 33.0447 23.0924"
            stroke="url(#paint4_linear_83_1219)" strokeWidth="3.4375" strokeLinecap="round"
            strokeLinejoin="round"/>
        <path d="M27.5 39.5626V37.1546M27.5 15.4978V18.2724" stroke="url(#paint5_linear_83_1219)"
              strokeWidth="3.4375" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_83_1219" x1="9.21874" y1="28.7164" x2="9.21874" y2="32.7853"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint1_linear_83_1219" x1="45.7812" y1="22.2919" x2="45.7812" y2="26.3608"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_83_1219" x1="28.0178" y1="8.05109" x2="28.0178" y2="26.3608"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint3_linear_83_1219" x1="26.8398" y1="28.7164" x2="26.8398" y2="47.0093"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint4_linear_83_1219" x1="27.5" y1="18.2578" x2="27.5" y2="36.8028"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint5_linear_83_1219" x1="28" y1="15.4978" x2="28" y2="39.5626"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
        </defs>
    </svg>,
    Megaphone   : <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_83_1227)">
            <path
                d="M35.3798 12.7806C32.6528 16.2397 29.1937 19.052 25.2508 21.0156L14.134 26.5123C13.2085 26.9686 12.4697 27.7315 12.0434 28.6713C11.6171 29.611 11.5296 30.6695 11.7959 31.6664L14.5217 41.8394C14.7734 42.856 15.3746 43.7519 16.2201 44.3699C17.0657 44.988 18.1017 45.289 19.1468 45.2201L31.5226 44.422C35.905 44.1272 40.2984 44.8037 44.3889 46.403C44.7735 46.5574 45.1935 46.6017 45.6018 46.5307C46.0101 46.46 46.3904 46.2767 46.7004 46.0019C47.0106 45.7269 47.2379 45.371 47.3572 44.9742C47.4763 44.5774 47.4827 44.155 47.3754 43.7548L39.2903 13.5808C39.1831 13.1805 38.9664 12.8181 38.6649 12.5339C38.3631 12.2498 37.9883 12.0553 37.5822 11.9723C37.1763 11.8892 36.7554 11.9208 36.3663 12.0636C35.9773 12.2063 35.6357 12.4546 35.3798 12.7806Z"
                stroke="url(#paint0_linear_83_1227)" strokeWidth="3.4375" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path
                d="M28.661 44.5188L34.8748 53.2259C35.0834 53.5183 35.2182 53.8566 35.2679 54.2122C35.3176 54.5678 35.2809 54.9299 35.1606 55.2684C35.0404 55.6067 34.8403 55.9111 34.5773 56.1555C34.3143 56.3998 33.9963 56.5773 33.6501 56.6725L31.1284 57.3482C30.2484 57.587 29.3164 57.5506 28.4577 57.2438C27.5991 56.937 26.855 56.3746 26.3257 55.6322L18.885 45.1054"
                stroke="url(#paint1_linear_83_1227)" strokeWidth="3.4375" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path d="M32.9791 24.512L36.4442 37.4436" stroke="url(#paint2_linear_83_1227)" strokeWidth="3.4375"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M45.62 18.2373L50.3576 13.5029" stroke="url(#paint3_linear_83_1227)" strokeWidth="3.4375"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M50.5288 36.5573L56.9989 38.2886" stroke="url(#paint4_linear_83_1227)" strokeWidth="3.4375"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M47.6434 27.5127L54.1092 25.7802" stroke="url(#paint5_linear_83_1227)" strokeWidth="3.4375"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_83_1227" x1="23.6254" y1="15.4681" x2="32.8655" y2="49.9527"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint1_linear_83_1227" x1="25.3975" y1="43.3604" x2="29.2784" y2="57.8439"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_83_1227" x1="33.4621" y1="24.3825" x2="36.9271" y2="37.3142"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint3_linear_83_1227" x1="47.5556" y1="14.2536" x2="48.4219" y2="17.4866"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint4_linear_83_1227" x1="53.3307" y1="35.8065" x2="54.197" y2="39.0394"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint5_linear_83_1227" x1="50.8763" y1="26.6465" x2="51.1351" y2="27.6124"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <clipPath id="clip0_83_1227">
                <rect width="55" height="55" fill="white" transform="translate(0.819519 15.0546) rotate(-15)"/>
            </clipPath>
        </defs>
    </svg>,
    Like        : <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.7067 23.4333L14.0034 23.2134C11.764 23.0312 9.80082 24.699 9.61864 26.9384L8.07918 45.8612C7.89698 48.1007 9.56473 50.0638 11.8042 50.246L14.5074 50.466C16.7469 50.6482 18.71 48.9804 18.8922 46.7409L20.4317 27.8181C20.6139 25.5787 18.9461 23.6155 16.7067 23.4333Z"
            stroke="url(#paint0_linear_83_1234)" strokeWidth="3.4375" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M51.3776 32.7574L46.8142 48.7131C46.4655 49.9376 45.6976 51.0006 44.6445 51.7157C43.5912 52.4311 42.3202 52.753 41.0536 52.6256L23.9688 51.2357C22.535 51.119 21.2061 50.4375 20.2747 49.3411C19.3433 48.2447 18.8855 46.8231 19.0022 45.3893L20.3217 29.1698C20.4499 28.1322 20.7962 27.1338 21.3379 26.2398C21.8796 25.346 22.6045 24.5768 23.4648 23.9831L26.8081 10.6493C26.8844 10.3381 27.033 10.0494 27.242 9.80648C27.451 9.56362 27.7145 9.37357 28.0107 9.25178C28.3071 9.13001 28.628 9.07996 28.9476 9.10575C29.2669 9.13153 29.5755 9.23235 29.8486 9.40006L32.2616 10.8481C33.8555 11.8044 35.0743 13.2779 35.7145 15.0232C36.3547 16.7684 36.3781 18.6803 35.7805 20.4407L34.28 24.8358L46.6069 25.8386C47.4087 25.9037 48.1861 26.1463 48.8824 26.5488C49.579 26.9514 50.1774 27.5038 50.6339 28.1661C51.0905 28.8284 51.3941 29.584 51.5229 30.3781C51.6514 31.1721 51.6019 31.985 51.3776 32.7574Z"
            stroke="url(#paint1_linear_83_1234)" strokeWidth="3.4375" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_83_1234" x1="15.355" y1="23.3233" x2="13.1558" y2="50.356"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
            <linearGradient id="paint1_linear_83_1234" x1="37.6254" y1="9.81174" x2="34.1877" y2="52.068"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#EEF5FE"/>
                <stop offset="0.31913" stopColor="#EFF0FE"/>
                <stop offset="0.597191" stopColor="#EEECFF"/>
                <stop offset="1" stopColor="#E8DDFF"/>
            </linearGradient>
        </defs>
    </svg>
}