import React, { useState, useRef, useEffect } from 'react';
import '../styles/GlowButton.css';
import {ButtonType} from "./Button";

export enum GlowButtonType {
    Link,
    Button
}

export default function GlowButton(props) {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const wrapperRef = useRef(null);

    useEffect(() => {
        wrapperRef.current.addEventListener("pointermove", applyOverlayMask);

        return () => {
            wrapperRef.current.removeEventListener("pointermove", applyOverlayMask);
        }
    }, []);

    const applyOverlayMask = (e) => {
        const offset = getOffset(e.currentTarget);
        setX(e.pageX - offset.x);
        setY(e.pageY - offset.y);
    };

    function getOffset(el) {
        let current = el;
        let result  = {x : 0, y : 0}
        while(current) {
            result.x += current.offsetLeft;
            result.y += current.offsetTop;

            current = current.offsetParent;
        }

        return result;
    }

    function renderHoverEffect() {
        return <div className={"btn-hover-wrapper"}>
            <span className={"btn-hover-effect" + (props.buttonType === ButtonType.CallToAction ? " light" : "")} style={{left: x, top: y}}></span>
        </div>
    }


    function renderContent(type) {
        if(props.type === GlowButtonType.Button) {
            return <button className={"button " + props.buttonType + "-glow base"} onClick={props.onClick}>
                <span className="btn-text">{props.children}</span>
                {renderHoverEffect()}
            </button>
        } else {
            return <a href={props.onClick} className={"button " + props.buttonType + "-glow base"}>
                <span className="btn-text">{props.children}</span>
                {renderHoverEffect()}
            </a>
        }
    }

    return (
        <div className={"glow-button " + (props.className || "")} ref={wrapperRef}>
            {renderContent(1)}
        </div>
    );
};

