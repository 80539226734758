import './styles/App.css';
import * as React from "react";
import {MainMenu} from "./components/Menu";
import {HeroSection} from "./components/HeroSection";
import {HowItWorks} from "./components/HowItWorks";
import {WhatWeDo} from "./components/WhatWeDo";
import {Pricing} from "./components/Pricing";
import {Testimonials} from "./components/Testimonials";
import {Faq} from "./components/Faq";
import {BannerCTA} from "./components/BannerCTA";
import {Footer} from "./components/Footer";

function App() {
    function BookACallButtonClicked() {
        // @ts-config
        window.open("https://calendly.com/najsu-code-design/20-min", '_blank')
    }
    return (
        <div className="App">
            <MainMenu bookACall={BookACallButtonClicked}/>
            <HeroSection bookACall={BookACallButtonClicked}/>
            <HowItWorks />
            <WhatWeDo />
            <Testimonials />
            <Pricing bookACall={BookACallButtonClicked}/>
            <Faq />
            <BannerCTA bookACall={BookACallButtonClicked}/>
            <Footer />
        </div>
    );
}

export default App;
