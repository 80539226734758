import "../styles/Footer.css";

export function Footer(props) {
    const year = new Date().getFullYear();
    return <div className="footer">
        <div className={"left-side"}>© Created by najsu {year}. All rights reserved</div>
        <div className={"right-side"}>
            {/*<a href={"#"} className={"link"}>Terms & conditions</a>*/}
            {/*<a href={"#"} className={"link"}>Privacy policy</a>*/}
            {/*<a href={"#"} className={"link"}>Client login</a>*/}
        </div>
    </div>
}
