import "../styles/Faq.css";
import * as React from "react";
import {Config} from "../Config";

export function Faq(props) {
    const accordions = [
        {
            head: "Is the number of requests really unlimited?",
            body: <div>
                <p>
                    Yes, definitely. Once you've subscribed, you'll be able to add unlimited design tasks in Trello board and we'll process them one by one based on your priorities. The amount of delivered designs can vary on the scope and complexity of the design tasks themselves.
                </p>
            </div>
        },
        {
            head: "Why wouldn’t I just hire a full-time designer and developer?",
            body: <div>
                <p>
                    That's a valid question! A full-time senior-level designer now costs around €36,000 to €45,000 annually, plus benefits, and good luck finding one who's available. Hiring a senior full-stack developer can cost up to €94,000 annually. We offer combined designer and developer services, providing you with skilled professionals in both areas at a fraction of the cost, ensuring a seamless and efficient solution for all your project needs.
                </p>
                <p>
                    What’s more, with the monthly plan, you have the flexibility to pause and resume your subscription as needed, ensuring you only pay your designer and developer when there’s work to be done.
                </p>
            </div>
        },
        {
            head: "How many designs can you deliver in a single month?",
            body: <div>
                <p>Design tasks usually take up to 2 business days, depending on their complexity. Social media assets or smaller print designs are usually ready within a day, while a complete web homepage design may require up to 3 days for the first draft. We create custom designs based on your specifications, without using templates.</p>
            </div>
        },
        {
            head: "Who are the people working on my designs?",
            body: <div>
                <p>Our agency is a team of two. Maria works on your designs and Michal takes care of all the code and technical part of the project. This means you'll work directly with us, the founders, ensuring personalized attention and seamless collaboration.</p>
            </div>
        },
        {
            head: "Do you work with other design agencies? I would like to hire you.",
            body: <div>
                <p>Totally! Our services are designed to fit the needs of startups, individual creatives looking to enhance their brand visuals as well as creative agencies in need of another pair of hands for design or both design and development. To discuss how we can assist with your projects, book a call for any of the packages listed above.</p>
            </div>
        },
        {
            head: "How do I pause my subscription?",
            body: <div>
                <p>
                    We know you might not always have a month's worth of design work. Perhaps you currently have only one or two design requests. This is where pausing of your subscription comes in handy.
                </p>
                <p>
                    Billing cycles are 31 days long. If you use the service for 15 days and then pause, the billing cycle stops, giving you 16 days of service that you can use any time in the future.
                </p>
            </div>
        },
        {
            head: "How do I sign up for your design services?",
            body: <div>
                <p>Simply select a package and book an intro call with us to get things rolling. The rest will be history!</p>
            </div>
        },
        {
            head: "Is there any design or development work that you don’t cover?",
            body: <div>
                <p>
                    Yes, there are some things we don’t do. Najsu does not cover 3D design, complex motion graphics, document design (such as medical forms), complex packaging design, and extensive print design (like magazines or books). We mainly focus on web design (and related things like blog graphics, newsletter design, banners, social media etc.) as well as interface design and branding.</p>
                <p>
                    If you're considering our Business Pro package, please note that we are not here to code someone else’s design. That would mean doing it without understanding its purpose and goals. You hire us as a team to handle both the design and development aspects of the project collaboratively.
                </p>
            </div>
        }
    ];
    const [opened, setOpened] = React.useState([]);

    function toggleOpened(index) {
        let newOpened = opened.slice(0);
        if(opened.includes(index)) {
            newOpened.splice(opened.indexOf(index), 1);
        } else {
            newOpened.push(index);
        }
        setOpened(newOpened)
    }

    return <div className="faq" id={Config.PageSections.FAQ}>
        <h2>
            Questions? Check out the <span>FAQ</span>
        </h2>
        {
            accordions.map((accordion, index) => {
                return <div className={"accordion" + (opened.includes(index) ? " opened" : "")} key={"accordion_" + index}
                            onClick={e => toggleOpened(index)}>
                    <div className="accordion-header"><span>{accordion.head}</span> {IconPlus}</div>
                    <div className="accordion-content">{accordion.body}</div>
                </div>
            })
        }
    </div>;
}

const IconPlus = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2778 12.9414L6.7222 12.9414" stroke="url(#paint0_linear_541_2443)" strokeWidth="1.5"
          strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 18.2191L12 7.66357" stroke="url(#paint1_linear_541_2443)" strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path
        d="M2.5 12.9414C2.5 18.1881 6.7533 22.4414 12 22.4414C17.2467 22.4414 21.5 18.1881 21.5 12.9414C21.5 7.69471 17.2467 3.44141 12 3.44141C6.7533 3.44141 2.5 7.69471 2.5 12.9414Z"
        stroke="url(#paint2_linear_541_2443)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <defs>
        <linearGradient id="paint0_linear_541_2443" x1="15.323" y1="13.0605" x2="15.317" y2="13.8489"
                        gradientUnits="userSpaceOnUse">
            <stop stopColor="#180030"/>
            <stop offset="1" stopColor="#4B0096"/>
        </linearGradient>
        <linearGradient id="paint1_linear_541_2443" x1="11.8148" y1="8.92019" x2="7.70124" y2="13.7121"
                        gradientUnits="userSpaceOnUse">
            <stop stopColor="#180030"/>
            <stop offset="1" stopColor="#4B0096"/>
        </linearGradient>
        <linearGradient id="paint2_linear_541_2443" x1="17.9815" y1="5.70331" x2="16.7711" y2="20.5866"
                        gradientUnits="userSpaceOnUse">
            <stop stopColor="#180030"/>
            <stop offset="1" stopColor="#4B0096"/>
        </linearGradient>
    </defs>
</svg>
